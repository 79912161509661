import React from 'react';
import '../styles/ConfirmPopup.css';
const ConfirmPopup = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirm-popup-overlay">
      <div className="confirm-popup">
        <p>{message}</p>
        <div className="confirm-popup-buttons">
          <button onClick={onConfirm} className="confirm-popup-confirm">Sim</button>
          <button onClick={onCancel} className="confirm-popup-cancel">Não</button>
        </div>
      </div>
    </div>
  );
};
export default ConfirmPopup;
